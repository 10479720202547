// @components/Feedback.component.tsx
import React from 'react';
import { View, Text, TextInput, Button, ActivityIndicator, ScrollView } from 'react-native';
import { FeedbackStyles as styles } from '@styles/Feedback.style';
import { Section } from '@@types/documents/core';

type RefineComponentProps = {
    feedback: string;
    setFeedback: (text: string) => void;
    onSubmit: () => void;
    mostRecentSection: Section | null;
};

const RefineComponent: React.FC<RefineComponentProps> = ({ feedback, setFeedback, onSubmit, mostRecentSection }) => {
    if (mostRecentSection) {
        return (
            <View style={styles.container}>
                <Text style={styles.header}>AI Refine</Text>
                <Text style={styles.subheader}>{mostRecentSection.metadata.name}:</Text>
                <ScrollView style={styles.sectionContainer}>
                    <Text style={styles.section}>{mostRecentSection.section_contents}</Text>
                </ScrollView>
                <TextInput
                    style={styles.input}
                    value={feedback}
                    onChangeText={setFeedback}
                    placeholder="Enter feedback here"
                    multiline
                />
                <Button title="Submit Feedback" onPress={onSubmit} />
            </View>
        );
    } else {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
                <Text>Loading ...</Text>
            </View>
        );
    }
};

export default RefineComponent;
