// @components/DocumentSelection.component
import React from 'react';
import { View, Text, Button, ScrollView } from 'react-native';
import { DocumentMetadata } from '@@types/documents/core';
import { DocumentSelectionStyles as styles } from '@styles/DocumentSelection.style';

export type DocumentSelectionComponentProps = {
    onCreateDocument: () => void;
    onSelectDocument: (documentID: string) => void;
    availableDocuments: DocumentMetadata[];
};

const DocumentSelectionComponent: React.FC<DocumentSelectionComponentProps> = ({
                                                                                   onCreateDocument,
                                                                                   onSelectDocument,
                                                                                   availableDocuments
                                                                               }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>Document Selection Page</Text>
            <ScrollView style={styles.documentList}>
                {availableDocuments.map((doc) => (
                    <View key={doc.id} style={styles.documentItem}>
                        <Text style={styles.documentName}>{doc.name}</Text>
                        <Button title="Select" onPress={() => onSelectDocument(doc.id)} />
                    </View>
                ))}
            </ScrollView>
            <Button title="Create New Document" onPress={onCreateDocument} />
        </View>
    );
};

export default DocumentSelectionComponent;
