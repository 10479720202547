// @styles/Prompt.style.ts
import { StyleSheet } from 'react-native';

export const PromptStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    probingQuestionContainer: {
        maxHeight: 400,  // Adjust the max height as needed
        marginBottom: 16,
    },
    probingQuestion: {
        fontSize: 16,
        lineHeight: 24,
    },
    input: {
        height: 200,
        borderColor: 'gray',
        borderWidth: 1,
        paddingLeft: 8,
        marginBottom: 16,
        textAlignVertical: 'top', // Ensure multiline text is top-aligned
    },
});

export default PromptStyles;
