import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { TextInput, Button, Card, Title } from 'react-native-paper';
import { gradientColors } from '@styles/Fancy.style';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import {Email, Password} from '@@types/clients/AccountAPI'

export type CreateAccountComponentProps = {
  onCreateAccount: (password: Password, email: Email) => void;
};
const SexyCreateAccountComponent: React.FC<CreateAccountComponentProps> = ({ onCreateAccount }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const handleSignUp = () => {
    if (password !== confirmPassword) {
      console.warn('Passwords do not match');
      return;
    }
    onCreateAccount(Password(password), Email(email))
  };

  return (
    <LinearGradient
      colors={gradientColors}
      style={sexyStyles.background}
    >
      <View style={styles.container}>
        <Card style={styles.card}>
          <Card.Content>
            <Title style={sexyStyles.title}>Sign Up</Title>
            <TextInput
              label="Email"
              value={email}
              onChangeText={text => setEmail(text)}
              style={sexyStyles.input}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <TextInput
              label="Password"
              value={password}
              onChangeText={text => setPassword(text)}
              style={sexyStyles.input}
              secureTextEntry
            />
            <TextInput
              label="Confirm Password"
              value={confirmPassword}
              onChangeText={text => setConfirmPassword(text)}
              style={sexyStyles.input}
              secureTextEntry
            />
            <Button mode="contained" onPress={handleSignUp} style={sexyStyles.button}>
              Sign Up
            </Button>
          </Card.Content>
        </Card>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    width: '85%',
    maxWidth: 550,
    padding: 20,
    borderRadius: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
});

export default SexyCreateAccountComponent;