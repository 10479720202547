// @pages/document/FinalEdits.page.tsx
import React, { useState } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@@types/navigation';
import FinalEditsComponent from '@components/document/FinalEdits.component';
import { useContentClient } from '@contexts/ContentClient.context';
import {useDocumentContext, useNonNullDocumentContext} from '@contexts/Document.context';

type FinalEditsProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.FINAL_EDITS>;
};

const FinalEditsPage: React.FC<FinalEditsProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { document, activeSectionID, updateSection } = useNonNullDocumentContext();
    const [finalEdits, setFinalEdits] = useState('');

    useFocusEffect(
        React.useCallback(() => {
            if (activeSectionID) {
                const section = document.document_contents.find(sec => sec.metadata.id === activeSectionID);
                if (section) {
                    setFinalEdits(section.section_contents || '');
                }
            }
        }, [document, activeSectionID])
    );

    const handleFinalEdits = () => {
        if (document && activeSectionID) {
            contentClient.replaceSection(document.id, { edits: finalEdits, section_id: activeSectionID })
                .then(response => {
                    updateSection(response.updated_section);
                    navigation.navigate(DocumentRoutes.REVIEW);
                })
                .catch(error => console.error('Error with final edits:', error));
        }
    };

    return <FinalEditsComponent finalEdits={finalEdits} setFinalEdits={setFinalEdits} onSubmit={handleFinalEdits} />;
};

export default FinalEditsPage;

