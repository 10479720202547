// @pages/document/Review.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@@types/navigation';
import SectionList from '@components/document/review/SectionList.component';
import { useContentClient } from '@contexts/ContentClient.context';
import {useDocumentContext, useNonNullDocumentContext} from '@contexts/Document.context';
import {useLifecycleClient} from "@contexts/LifecycleClient.context";

type ReviewProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.REVIEW>;
};

const ReviewPage: React.FC<ReviewProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { lifecycleClient } = useLifecycleClient();

    const { document, setDocument, updateSection, activeSectionID } = useNonNullDocumentContext();



    const handleDraft = () => {
        contentClient.generateSectionQuestions(document.id, { section_id: activeSectionID! })
            .then(response => {
                const { probing_questions } = response;
                navigation.navigate(DocumentRoutes.PROMPT, { probingQuestion: probing_questions });
            })
            .catch(error => console.error(`Error generating AI questions for section ${activeSectionID}:`, error));
    };

    const handleRefinement = () => {
        navigation.navigate(DocumentRoutes.REFINE );
    };

    const handleEdits = (newContents: string) => {
        contentClient.replaceSection(document.id, { section_id: activeSectionID!, edits: newContents })
            .then(response => {
                updateSection(response.updated_section); // Update the section in the context
            })
            .catch(error => console.error(`Error replacing content for section ${activeSectionID}:`, error));
    };

    return (
        <SectionList
            handleDraft={handleDraft}
            handleRefine={handleRefinement}
            handleEdits={handleEdits}
        />
    );
};

export default ReviewPage;
