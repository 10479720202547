// @contexts/AccountClient.context.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { AccountClient } from '@services/clients/AccountClient';
import { DefaultAccountClient } from "@services/ouroboros/DefaultAccountClient";
import { StubAccountClient } from "@services/stubs/StubAccountClient";
import { useLoadingSetters } from '@contexts/Loading.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';

interface AccountClientContextType {
  accountClient: AccountClient;
}

const AccountClientContext = createContext<AccountClientContextType | undefined>(undefined);

export const AccountClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Get the loading setters context object
  const loadingSetters = useLoadingSetters();

  // Memoize the creation of AccountClient, conditionally using the StubAccountClient if the flag is set
  const accountClient = useMemo(() => {
    if (enabledFeatures[FeaturesEnum.StubClients]) {
      return new StubAccountClient() as AccountClient; // Use the stub implementation
    } else {
      return new DefaultAccountClient(loadingSetters) as AccountClient; // Use the default implementation
    }
  }, [loadingSetters]);

  const contextValue = useMemo(() => ({
    accountClient,
  }), [accountClient]);

  return (
      <AccountClientContext.Provider value={contextValue}>
        {children}
      </AccountClientContext.Provider>
  );
};

export const useAccountClient = (): AccountClientContextType => {
  const context = useContext(AccountClientContext);
  if (!context) {
    throw new Error('useAccountClient must be used within an AccountClientProvider');
  }
  return context;
};
