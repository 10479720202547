// @services/ouroboros/DefaultContentClient.ts
import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID } from '@@types/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse
} from '@@types/clients/ContentAPI';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { LoadingSetters } from '@contexts/Loading.context';
import { isRight } from 'fp-ts/Either';

export class DefaultContentClient implements ContentClient {
    private readonly setLoading: () => void;
    private readonly setSuccess: () => void;
    private readonly setError: (message: string, info?: string) => void;

    constructor({ setLoading, setSuccess, setError }: LoadingSetters) {
        this.setLoading = setLoading;
        this.setSuccess = setSuccess;
        this.setError = setError;
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private async fetchWithValidation<T>(url: string, options: RequestInit, validator: t.Type<T>): Promise<T> {
        const traceId = this.generateTraceId();

        options.credentials = 'include';
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };

        this.setLoading();

        try {
            const response = await this.fetchData(url, options);
            return this.validateResponse(response, validator, traceId);
        } catch (error) {
            this.handleError(error, traceId);
            throw error;
        }
    }

    private async fetchData(url: string, options: RequestInit): Promise<Response> {
        const response = await fetch(url, options);
        if (!response.ok) {
            throw new Error(`Server responded with an error: ${response.status}`);
        }
        return response;
    }

    private async validateResponse<T>(response: Response, validator: t.Type<T>, traceId: string): Promise<T> {
        const data = await response.json();
        if (!isRight(validator.decode(data))) {
            throw new Error(`Response validation failed (Trace ID: ${traceId})`);
        }
        this.setSuccess();
        return data;
    }

    private handleError(error: unknown, traceId: string): void {
        if (error instanceof Error) {
            this.setError(error.message, `Trace ID: ${traceId}`);
        } else {
            this.setError('An unknown error occurred', `Trace ID: ${traceId}`);
        }
    }

    async refineSection(documentId: DocumentID, request: RefineRequest): Promise<RefineResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/refine`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                },
                body: JSON.stringify(request)
            },
            RefineResponse
        );
    }

    async replaceSection(documentId: DocumentID, request: ReplaceSectionRequest): Promise<ReplaceSectionResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/replace-section`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                },
                body: JSON.stringify(request)
            },
            ReplaceSectionResponse
        );
    }

    async answerSectionQuestions(documentId: DocumentID, request: AnswerSectionQuestionsRequest): Promise<AnswerSectionQuestionsResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/answer-section-questions`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                },
                body: JSON.stringify(request)
            },
            AnswerSectionQuestionsResponse
        );
    }

    async generateSectionQuestions(documentId: DocumentID, request: GenerateSectionQuestionsRequest): Promise<GenerateSectionQuestionsResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/generate-section-questions`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                },
                body: JSON.stringify(request)
            },
            GenerateSectionQuestionsResponse
        );
    }

    async answerDocumentQuestions(documentId: DocumentID, request: AnswerDocumentQuestionsRequest): Promise<void> {
        const traceId = this.generateTraceId();
        const url = `${API_URL}/v1/apps/writer/document/answer-document-questions`;

        const options: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Trace-ID': traceId,
                'X-Document-ID': documentId
            },
            body: JSON.stringify(request),
            credentials: 'include'
        };

        this.setLoading();

        try {
            const response = await this.fetchData(url, options);
            this.setSuccess();
        } catch (error) {
            this.handleError(error, traceId);
            throw error;
        }
    }

    async generateDocumentQuestions(documentId: DocumentID): Promise<GenerateDocumentQuestionsResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/generate-document-questions`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                }
            },
            GenerateDocumentQuestionsResponse
        );
    }
}
