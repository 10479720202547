// @components/writing/review/SectionActions.component.tsx
import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Card } from 'react-native-paper';
import { SectionID } from '@@types/documents/core';

type SectionActionsProps = {
    sectionID: SectionID;
    sectionContents: string | null;
    handleDraft: (sectionID: SectionID) => void;
    handleRefine: (sectionID: SectionID) => void;
    handleFinalEdits: (sectionID: SectionID, newContents: string) => void;
};

const SectionActions: React.FC<SectionActionsProps> = ({
                                                           sectionID,
                                                           sectionContents,
                                                           handleDraft,
                                                           handleRefine,
                                                           handleFinalEdits
                                                       }) => {
    return (
        <Card style={styles.actionCard}>
            <Card.Content>
                <View style={styles.buttonContainer}>
                    {!sectionContents && (
                        <Button onPress={() => handleDraft(sectionID)}>Draft</Button>
                    )}
                    {sectionContents && (
                        <>
                            <Button onPress={() => handleRefine(sectionID)}>Refine</Button>
                            <Button onPress={() => handleFinalEdits(sectionID, sectionContents)}>Edit</Button>
                        </>
                    )}
                </View>
            </Card.Content>
        </Card>
    );
};

const styles = StyleSheet.create({
    actionCard: {
        marginTop: -8, // Move the card closer to the section above it
        marginBottom: 8, // Ensure there's still space below
        backgroundColor: '#f9f9f9', // Lighter background color for better contrast
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export default SectionActions;
