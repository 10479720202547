// @pages/document/DocumentDetailsPrompt.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import {AppRoutes, WorkspaceRoutes, DocumentRoutes} from '@navigation/routes';
import {AppStackParamList, DocumentRoutesParamList, WorkspaceRoutesParamList} from '@@types/navigation';
import DocumentDetailsPromptComponent from '@components/document/DocumentDetailsPrompt.component';
import { Markdown } from '@@types/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import {useLifecycleClient} from "@contexts/LifecycleClient.context";
import appNavigator from "@navigation/navigator/App.navigator";
import {useDocumentID} from "@contexts/Document.context";

type DocumentDetailsPromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.DOCUMENT_LEVEL_PROMPT>;
};

const DocumentDetailsPromptPage: React.FC<DocumentDetailsPromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { lifecycleClient } = useLifecycleClient();
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown};
    const documentID = useDocumentID();

    const handlePromptSubmit = (userInput: string) => {
            contentClient.answerDocumentQuestions(documentID, {answers: userInput})
                .then(() => {
                    navigation.navigate(DocumentRoutes.REVIEW)
                })
                .catch(error => console.error('Error submitting document-level prompt:', error));
    };

    return <DocumentDetailsPromptComponent documentQuestion={probingQuestion} onSubmit={handlePromptSubmit} />;
};

export default DocumentDetailsPromptPage;
