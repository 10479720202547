import { AccountClient } from '@services/clients/AccountClient';
import { CreateAccountRequest, LoginRequest } from '@@types/Account';

export class StubAccountClient implements AccountClient {
    async createAccount(request: CreateAccountRequest): Promise<void> {
        console.log('StubAccountClient.createAccount called', request);
        // Simulate a delay
        await new Promise(resolve => setTimeout(resolve, 100));
        // Simulate a successful account creation
    }

    async login(request: LoginRequest): Promise<Response> {
        console.log('StubAccountClient.login called', request);
        // Simulate a delay
        await new Promise(resolve => setTimeout(resolve, 100));
        // Simulate a successful login response
        return new Response(JSON.stringify({ message: 'Login successful' }), { status: 200 });
    }
}
