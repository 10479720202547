// @navigation/navigators/Auth.navigator.tsx
import React from 'react';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { AuthStackParamList, AppStackParamList } from '@@types/navigation';
import LoginPage from '@pages/auth/Login.page';
import CreateAccountPage from '@pages/auth/CreateAccount.page';
import { AppRoutes, AuthRoutes } from '@navigation/routes';
import ForgotPasswordPage from '@src/pages/auth/ForgotPassword.page';

const Stack = createStackNavigator<AuthStackParamList>();

type AuthNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const AuthNavigator: React.FC<AuthNavigatorProps> = ({ navigation }) => {
    return (
        <Stack.Navigator
            initialRouteName={AuthRoutes.LOGIN}
            screenOptions={{
                headerShown: true,
                cardStyle: { backgroundColor: 'transparent' },
                detachPreviousScreen: false
            }}
        >
            <Stack.Screen name={AuthRoutes.LOGIN}>
                {props => <LoginPage {...props} appNavigator={navigation} />}
            </Stack.Screen>
            <Stack.Screen name={AuthRoutes.CREATE_ACCOUNT}>
                {props => <CreateAccountPage {...props} appNavigator={navigation} />}
            </Stack.Screen>
            <Stack.Screen name={AuthRoutes.FORGOT_PASSWORD}>
                {props => <ForgotPasswordPage {...props} appNavigator={navigation} />}
            </Stack.Screen>
        </Stack.Navigator>
    );
};

export default AuthNavigator;
