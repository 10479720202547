import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';
import {UserDocument, Section, SectionID, DocumentID} from '@@types/documents/core';

// The type of the DocumentContext when the document may be null
type DocumentContextType = {
    document: UserDocument | null;
    activeSectionID: SectionID | null;
    setDocument: (doc: UserDocument) => void;
    setActiveSectionID: (id: SectionID) => void;
    updateSection: (updatedSection: Section) => void;
};

// The type of the DocumentContext when the document is guaranteed to be non-null
type NonNullDocumentContextType = {
    document: UserDocument;
    activeSectionID: SectionID | null;
    setDocument: (doc: UserDocument) => void;
    setActiveSectionID: (id: SectionID) => void;
    updateSection: (updatedSection: Section) => void;
};

const DocumentContext = createContext<DocumentContextType | undefined>(undefined);

type DocumentProviderProps = {
    children: ReactNode;
};

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
    const [document, setDocument] = useState<UserDocument | null>(null);
    const [activeSectionID, setActiveSectionID] = useState<SectionID | null>(null);

    const updateSection = useCallback((updatedSection: Section): void => {
        setDocument(prevDocument => {
            if (!prevDocument) return prevDocument;

            const updatedContents = prevDocument.document_contents.map(section =>
                section.metadata.index === updatedSection.metadata.index ? updatedSection : section
            );

            return { ...prevDocument, document_contents: updatedContents };
        });
    }, []);

    const contextValue = useMemo(() => ({
        document,
        activeSectionID,
        setDocument,
        setActiveSectionID,
        updateSection,
    }), [document, activeSectionID, setDocument, setActiveSectionID, updateSection]);

    return (
        <DocumentContext.Provider value={contextValue}>
            {children}
        </DocumentContext.Provider>
    );
};

export const useDocumentContext = () => {
    const context = useContext(DocumentContext);
    if (!context) {
        throw new Error('useDocumentContext must be used within a DocumentProvider');
    }
    return context;
};

// This hook guarantees that the document is non-null
export const useNonNullDocumentContext = (): NonNullDocumentContextType => {
    const context = useDocumentContext();
    if (!context.document) {
        throw new Error('Document is not set in DocumentContext');
    }

    // Cast the context to the NonNullDocumentContextType, which guarantees document is non-null
    return {
        ...context,
        document: context.document as UserDocument,  // Here we are asserting that document is non-null
    };
};

// Helper hook to get the document ID safely
export const useDocumentID = (): DocumentID => {
    const { document } = useNonNullDocumentContext();
    return document.id; // Since we are using the non-null context, document.id is guaranteed to be accessible
};
