// @contexts/LifecycleClient.context.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DefaultLifecycleClient } from '@services/ouroboros/DefaultLifecycleClient';
import { StubLifecycleClient } from '@services/stubs/StubLifecycleClient';
import { LoadingSetters, useLoadingSetters } from '@contexts/Loading.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';

interface LifecycleClientContextType {
    lifecycleClient: LifecycleClient;
}

const LifecycleClientContext = createContext<LifecycleClientContextType | undefined>(undefined);

export const LifecycleClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Extract only the stable setters from the loading context
    const loadingSetters: LoadingSetters = useLoadingSetters();

    // Create the lifecycle client, conditionally using the StubLifecycleClient if the flag is set
    const lifecycleClient = useMemo<LifecycleClient>(() => {
        if (enabledFeatures[FeaturesEnum.StubClients]) {
            return new StubLifecycleClient(); // Use the stub implementation
        } else {
            return new DefaultLifecycleClient(loadingSetters); // Use the default implementation
        }
    }, [loadingSetters]);

    const contextValue = useMemo(() => ({
        lifecycleClient,
    }), [lifecycleClient]);

    return (
        <LifecycleClientContext.Provider value={contextValue}>
            {children}
        </LifecycleClientContext.Provider>
    );
};

export const useLifecycleClient = (): LifecycleClientContextType => {
    const context = useContext(LifecycleClientContext);
    if (!context) {
        throw new Error('useLifecycleClient must be used within a LifecycleClientProvider');
    }
    return context;
};
