// @pages/document/Refine.page.tsx
import React, { useState, useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@@types/navigation';
import RefineComponent from '@components/document/Refine.component';
import { useContentClient } from '@contexts/ContentClient.context';
import {useDocumentContext, useNonNullDocumentContext} from '@contexts/Document.context';
import { Section } from '@@types/documents/core';

type RefineProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.REFINE>;
};

const RefinePage: React.FC<RefineProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { activeSectionID, updateSection, document } = useNonNullDocumentContext();
    const [feedback, setFeedback] = useState('');
    const [mostRecentSection, setMostRecentSection] = useState<Section | null>(null);

    useEffect(() => {
        if (activeSectionID && document) {
            const section = document.document_contents.find(sec => sec.metadata.id === activeSectionID) || null;
            setMostRecentSection(section);
        }
    }, [activeSectionID, document]);

    const handleRefinement = () => {
        if (mostRecentSection) {
            contentClient.refineSection(document.id, { feedback, section_id: mostRecentSection.metadata.id })
                .then(response => {
                    updateSection(response.updated_section);
                    navigation.navigate(DocumentRoutes.REVIEW);
                })
                .catch(error => console.error('Error with refinement:', error));
        }
    };

    return <RefineComponent feedback={feedback} setFeedback={setFeedback} onSubmit={handleRefinement} mostRecentSection={mostRecentSection} />;
};

export default RefinePage;
