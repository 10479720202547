// @@types/clients/ContentAPI.ts

import * as t from 'io-ts';
import { DocumentID, SectionID, UserDocument, Section } from '@@types/documents/core';
import {Markdown} from "@@types/markdown";  // Assuming shared types exist

// Generate Section Questions Request & Response
export const GenerateSectionQuestionsRequest = t.type({
    section_id: SectionID,
});
export type GenerateSectionQuestionsRequest = t.TypeOf<typeof GenerateSectionQuestionsRequest>;

export const GenerateSectionQuestionsResponse = t.type({
    probing_questions: Markdown,
});
export type GenerateSectionQuestionsResponse = t.TypeOf<typeof GenerateSectionQuestionsResponse>;

// Answer Section Questions Request & Response
export const AnswerSectionQuestionsRequest = t.type({
    section_id: SectionID,
    answers: Markdown,
});
export type AnswerSectionQuestionsRequest = t.TypeOf<typeof AnswerSectionQuestionsRequest>;

export const AnswerSectionQuestionsResponse = t.type({
    updated_section: Section,
});
export type AnswerSectionQuestionsResponse = t.TypeOf<typeof AnswerSectionQuestionsResponse>;

export const GenerateDocumentQuestionsResponse = t.type({
    probing_questions: Markdown,
});
export type GenerateDocumentQuestionsResponse = t.TypeOf<typeof GenerateDocumentQuestionsResponse>;

// Answer Document Questions Request & Response
export const AnswerDocumentQuestionsRequest = t.type({
    answers: Markdown,
});
export type AnswerDocumentQuestionsRequest = t.TypeOf<typeof AnswerDocumentQuestionsRequest>;

// Replace Section Request & Response
export const ReplaceSectionRequest = t.type({
    section_id: SectionID,
    edits: Markdown,
});
export type ReplaceSectionRequest = t.TypeOf<typeof ReplaceSectionRequest>;

export const ReplaceSectionResponse = t.type({
    updated_section: Section,
});
export type ReplaceSectionResponse = t.TypeOf<typeof ReplaceSectionResponse>;

export const FeedbackMessage = t.string;
export type FeedbackMessage = t.TypeOf<typeof FeedbackMessage>;

export const RefineRequest = t.type({
    feedback: FeedbackMessage,
    section_id: SectionID // Include section_id to identify the section being refined
});
export type RefineRequest = t.TypeOf<typeof RefineRequest>;

export const RefineResponse = t.type({
    updated_section: Section // Include the updated section
});
export type RefineResponse = t.TypeOf<typeof RefineResponse>;
