import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { View, StyleSheet, FlatList, Pressable } from 'react-native';
import { TextInput, Card, Title, ActivityIndicator, Divider, Text } from 'react-native-paper';
import { format, isWithinInterval, subDays } from 'date-fns';
import { Ionicons } from '@expo/vector-icons';
import { gradientColors } from '@styles/Fancy.style';
import { chooseStatusIcon } from '@utils/documents/documentUtils';
import { DocumentSelectionComponentProps } from '@components/workspace/DocumentSelection.component';
import { DocumentMetadata } from '@src/types/documents/core';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';


const SexyDocumentsListComponent: React.FC<DocumentSelectionComponentProps> = ({ availableDocuments, onCreateDocument, onSelectDocument }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [items, setItems] = useState<DocumentMetadata[]>(enabledFeatures[FeaturesEnum.DemoDocumentData] ? [
    {
    "name": "A Beautiful Morning",
    "status": "Unstarted",
    "last_edited": subDays(new Date(), 2).toUTCString(),
    "id": "e52bbeeb-2e12-46b1-826f-344d058289eb"
    },
    {
    "name": "Dark Clouds Arrive",
    "status": "Active",
    "last_edited": subDays(new Date(), 4).toUTCString(),
    "id": "054407b3-c1d8-41f4-94a2-dbb675b9163c"
    },
    {
    "name": "A Cozy Nook",
    "status": "Error",
    "last_edited": subDays(new Date(), 8).toUTCString(),
    "id": "f3b7a164-1463-410f-abfe-971ae0a63a2f"
    },
    {
    "name": "A Nozy Cook",
    "status": "Started",
    "last_edited": subDays(new Date(), 9).toUTCString(),
    "id": "f3b7a164-1463-410f-abfe-971ae0a63a2f"
    }
    ]: availableDocuments);


  useEffect(() => {
    // Sort items by lastEdited date in descending order
    const sortedItems = items.sort((a, b) => new Date(b.last_edited).getTime()  -  new Date(a.last_edited).getTime());
    setItems(sortedItems);
  }, [items]);


  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleItemPress = (document: DocumentMetadata) => {
     onSelectDocument(document.id)
  };

  const last7Days = filteredItems.filter(item =>
    isWithinInterval(item.last_edited, { start: subDays(new Date(), 7), end: new Date() })
  );

  const last30Days = filteredItems.filter(item =>
     isWithinInterval(item.last_edited, { start: subDays(new Date(), 30), end: subDays(new Date(), 8) })
  );

  const renderSection = (title: string, data: DocumentMetadata[]) => (
    <View key={title}>
      <Title style={styles.sectionTitle}>{title}</Title>
      {data.map(item => (
        <Pressable key={item.id} onPress={() => handleItemPress(item)}>
            <Card style={styles.card}>
            <Card.Content style={styles.cardContent}>
              {item.status === 'Active' ? (
                <ActivityIndicator animating={true} />
              ) : (
                <Ionicons name="document-text" size={24} color="black" />
              )}
              <View style={styles.titleContainer}>
                <Title style={styles.title}>{item.name}</Title>
                <Text style={styles.date}>Last Edited: {format(new Date(item.last_edited), 'MMM dd yyyy')}</Text>
              </View>
              <Divider style={styles.divider} />
              <React.Fragment>
                {chooseStatusIcon(item)}
                <Text style={styles.statusText}>{item.status}</Text>
              </React.Fragment>
            </Card.Content>
          </Card>
        </Pressable>
      ))}
    </View>
  );

  return (
    <LinearGradient
      colors={gradientColors}
      style={styles.background}
    >
       <View style={styles.container}>
        {enabledFeatures[FeaturesEnum.DocumentSearching] && <TextInput
          label="Search"
          value={searchQuery}
          onChangeText={query => setSearchQuery(query)}
          style={styles.searchBar}
        />}
        <FlatList
          ListHeaderComponent={() => (
            <>
              {renderSection('Edited within the last 7 days', last7Days)}
              {renderSection('Edited within the last 30 days', last30Days)}
            </>
          )}
          data={[]}
          keyExtractor={(item: DocumentMetadata) => item.id}
          renderItem={() => null}
        />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  titleContainer: {
    flex: 1,
    marginLeft: 16,
  },
  searchBar: {
    marginBottom: 16,
  },
  card: {
    marginBottom: 10,
  },
  cardContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    marginLeft: 16,
  },
  divider: {
    height: '100%',
    width: 1,
    backgroundColor: '#000',
    marginHorizontal: 16,
  },
  sectionTitle: {
    marginTop: 16,
    marginBottom: 8,
    color: 'white'
  },
  status: {
    flex: 1,
    flexDirection: 'column',
    fontWeight: 'bold',
  },
  statusText: {
    padding: 1,
    marginLeft: 10
  },
  date: {
    color: '#888',
  }
});


export default SexyDocumentsListComponent;