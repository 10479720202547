import React, { useState } from 'react';
import { View, Text, TextInput, Button, ScrollView } from 'react-native';
import { Markdown as MarkdownType} from '@@types/markdown';
import { DocumentLevelPromptStyles as styles } from '@styles/DocumentLevelPrompt.style';
import Markdown from "react-native-markdown-display";

type DocumentDetailsPromptProps = {
    documentQuestion: MarkdownType;
    onSubmit: (userInput: string) => void;
};

const DocumentDetailsPromptComponent: React.FC<DocumentDetailsPromptProps> = ({ documentQuestion, onSubmit }) => {
    const [userInput, setUserInput] = useState<string>('');

    const handleOnSubmit = () => {
        onSubmit(userInput);
        setUserInput(''); // Clear the input field
    };

    return (
        <View style={styles.container}>
            <Text style={styles.header}>Document Level Question</Text>
            <ScrollView style={styles.documentQuestionContainer}>
                <Markdown>{documentQuestion}</Markdown>
            </ScrollView>
            <TextInput
                style={styles.input}
                value={userInput}
                onChangeText={setUserInput}
                placeholder="You can also provide additional context or examples in addition to responding to the questions."
                multiline={true}
            />
            <Button title="Submit" onPress={handleOnSubmit} />
        </View>
    );
};

export default DocumentDetailsPromptComponent;
