// @pages/document/Prompt.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { useRoute } from '@react-navigation/native';
import { DocumentRoutes } from '@navigation/routes';
import { DocumentRoutesParamList } from '@@types/navigation';
import PromptComponent from '@components/document/Prompt.component';
import { Markdown } from '@@types/markdown';
import { useContentClient } from '@contexts/ContentClient.context';
import {useDocumentContext, useNonNullDocumentContext} from '@contexts/Document.context';
import {useLifecycleClient} from "@contexts/LifecycleClient.context";

type PromptProps = {
    navigation: StackNavigationProp<DocumentRoutesParamList, typeof DocumentRoutes.PROMPT>;
};

const PromptPage: React.FC<PromptProps> = ({ navigation }) => {
    const { contentClient } = useContentClient();
    const { lifecycleClient } = useLifecycleClient();
    const { document, setDocument, updateSection, activeSectionID } = useNonNullDocumentContext();
    const route = useRoute();
    const { probingQuestion } = route.params as { probingQuestion: Markdown };

    const handlePromptSubmit = (userInput: string) => {
        if (document && activeSectionID) {
            contentClient.answerSectionQuestions(document.id, { answers: userInput, section_id: activeSectionID })
                .then(response => {
                    updateSection(response.updated_section);
                    navigation.navigate(DocumentRoutes.REVIEW);
                })
                .catch(error => console.error('Error submitting prompt response:', error));
        } else {
            console.error('Document or Section ID is missing');
        }
    };

    return <PromptComponent probingQuestion={probingQuestion} onSubmit={handlePromptSubmit} />;
};

export default PromptPage;
