// @components/writing/review/Section.component.tsx
import React from 'react';
import { Section as SectionType, SectionID } from '@@types/documents/core';
import SectionCard from './SectionCard.component';
import SectionActions from './SectionActions.component';

type SectionProps = {
    section: SectionType;
    active: boolean;
    handleRefine: (sectionID: SectionID) => void;
    handleFinalEdits: (sectionID: SectionID, newContents: string) => void;
    handleDraft: (sectionID: SectionID) => void;
    setActiveSection: (sectionID: SectionID) => void;
};

const Section: React.FC<SectionProps> = ({
                                             section,
                                             active,
                                             handleRefine,
                                             handleFinalEdits,
                                             handleDraft,
                                             setActiveSection
                                         }) => {
    const onPress = () => {
        setActiveSection(section.metadata.id);
    };

    return (
        <>
            <SectionCard section={section} active={active} onPress={onPress} />
            {active && (
                <SectionActions
                    sectionID={section.metadata.id}
                    sectionContents={section.section_contents}
                    handleDraft={handleDraft}
                    handleRefine={handleRefine}
                    handleFinalEdits={handleFinalEdits}
                />
            )}
        </>
    );
};

export default React.memo(Section, (prevProps, nextProps) => {
    return prevProps.active === nextProps.active && prevProps.section === nextProps.section;
});
