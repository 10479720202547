// @components/FinalEdits.component.tsx
import React from 'react';
import { View, Text, TextInput, Button } from 'react-native';
import { FinalEditsStyles as styles } from '@styles/FinalEdits.style';

type FinalEditsComponentProps = {
    finalEdits: string;
    setFinalEdits: (text: string) => void;
    onSubmit: () => void;
};

const FinalEditsComponent: React.FC<FinalEditsComponentProps> = ({ finalEdits, setFinalEdits, onSubmit }) => {
    return (
        <View style={styles.container}>
            <Text style={styles.header}>Final Edits</Text>
            <TextInput
                style={styles.input}
                value={finalEdits}
                onChangeText={setFinalEdits}
                placeholder="Enter final edits here"
                multiline
            />
            <Button title="Submit Final Edits" onPress={onSubmit} />
        </View>
    );
};

export default FinalEditsComponent;