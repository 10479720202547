// @contexts/ContentClient.context.tsx
import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { ContentClient } from '@services/clients/ContentClient';
import { DefaultContentClient } from '@services/ouroboros/DefaultContentClient';
import { StubContentClient } from '@services/stubs/StubContentClient';
import { useLoadingSetters } from '@contexts/Loading.context';
import { useNonNullDocumentContext } from '@contexts/Document.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';

interface ContentClientContextType {
    contentClient: ContentClient;
}

const ContentClientContext = createContext<ContentClientContextType | undefined>(undefined);

export const ContentClientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Extract the necessary data from the document context
    const { document } = useNonNullDocumentContext();

    // Extract only the stable setters from the loading context
    const loadingSetters = useLoadingSetters();

    // Create the content client, conditionally using the StubContentClient if the flag is set
    const contentClient = useMemo<ContentClient>(() => {
        if (enabledFeatures[FeaturesEnum.StubClients]) {
            return new StubContentClient(); // Use the stub implementation
        } else {
            return new DefaultContentClient(loadingSetters); // Use the default implementation
        }
    }, [loadingSetters]);

    const contextValue = useMemo(() => ({
        contentClient,
    }), [contentClient]);

    return (
        <ContentClientContext.Provider value={contextValue}>
            {children}
        </ContentClientContext.Provider>
    );
};

export const useContentClient = (): ContentClientContextType => {
    const context = useContext(ContentClientContext);
    if (!context) {
        throw new Error('useContentClient must be used within a ContentClientProvider');
    }
    return context;
};
