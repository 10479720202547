import { AccountClient } from '@services/clients/AccountClient';
import { CreateAccountRequest, LoginRequest } from '@@types/clients/AccountAPI';
import API_URL from '@utils/config';
import { v7 as uuidv7 } from 'uuid';
import { LoadingSetters } from "@contexts/Loading.context";

export class DefaultAccountClient implements AccountClient {
    private readonly setLoading_: () => void;
    private readonly setSuccess_: () => void;
    private readonly setError_: (message: string, info?: string) => void;

    constructor({ setLoading, setSuccess, setError }: LoadingSetters) {
        this.setLoading_ = setLoading;
        this.setSuccess_ = setSuccess;
        this.setError_ = setError;
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private async fetchWithTrace(url: string, options: RequestInit): Promise<Response> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };
        options.credentials = 'include';
        this.setLoading_();

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
                throw new Error(`Server responded with an error: ${response.status}`);
            }
            this.setSuccess_();
            return response;
        } catch (error) {
            this.handleError(error, traceId);
            throw error;
        }
    }

    private handleError(error: unknown, traceId: string): void {
        if (error instanceof Error) {
            if (error.message.includes('NetworkError')) {
                this.setError_('Failed to connect to the server. Please check your internet connection.', `Trace ID: ${traceId}`);
            } else if (error.message.includes('Server responded with an error')) {
                this.setError_('The server encountered an error. Please try again later.', `Trace ID: ${traceId}`);
            } else {
                this.setError_(error.message, `Trace ID: ${traceId}`);
            }
        } else {
            this.setError_('An unknown error occurred', `Trace ID: ${traceId}`);
        }
    }

    async createAccount(request: CreateAccountRequest): Promise<void> {
        try {
            const response = await this.fetchWithTrace(
                `${API_URL}/v1/auth/create-account`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                }
            );

            if (response.status === 409) {
                this.setError_('An account with this email already exists. Please use a different email or log in.', `Trace ID: ${this.generateTraceId()}`);
            }
        } catch (error) {
            // Error handling is already done in fetchWithTrace
        }
    }

    async login(request: LoginRequest): Promise<Response> {
        const response = await this.fetchWithTrace(
            `${API_URL}/v1/auth/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            }
        );

        if (response.status === 401) {
            this.setError_('Incorrect username or password. Please try again.', `Trace ID: ${this.generateTraceId()}`);
        }
        return response;
    }
}
