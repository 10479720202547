import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native';
import { TextInput, Button, Card, Title } from 'react-native-paper';
import { gradientColors } from '@styles/Fancy.style';
import { NavigationContainerRefWithCurrent, useNavigation } from '@react-navigation/native';
import { AuthStackParamList } from '@src/types/navigation';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import {Email} from "@@types/clients/AccountAPI";

interface ForgotPasswordPageProps {
  onForgotPasswordSubmit: (email: Email) => Promise<void>
}

const SexyCreateForgotPasswordComponent: React.FC<ForgotPasswordPageProps> = ({ onForgotPasswordSubmit }) => {
  const [email, setEmail] = useState<string>('');

  const handleResetPassword = () => {
    console.log('Reset password for:', email);
    onForgotPasswordSubmit(Email(email));
  };

  return (
    <LinearGradient
      colors={gradientColors}
      style={sexyStyles.background}
    >
      <View style={sexyStyles.container}>
        <Card style={sexyStyles.card}>
          <Card.Content>
            <Title style={sexyStyles.title}>Forgot Password</Title>
            <TextInput
              label="Email"
              value={email}
              onChangeText={text => setEmail(text)}
              style={sexyStyles.input}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <Button mode="contained" onPress={handleResetPassword} style={sexyStyles.button}>
              Reset Password
            </Button>
          </Card.Content>
        </Card>
      </View>
    </LinearGradient>
  );
};

export default SexyCreateForgotPasswordComponent;
