// @styles/DocumentSelection.style
import { StyleSheet } from 'react-native';

export const DocumentSelectionStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    text: {
        fontSize: 20,
        marginBottom: 16,
    },
    documentList: {
        flex: 1,
        marginBottom: 16,
    },
    documentItem: {
        marginBottom: 16,
        padding: 16,
        backgroundColor: '#f0f0f0',
        borderRadius: 8,
    },
    documentName: {
        fontSize: 18,
        marginBottom: 8,
    },
});

