// @styles/Feedback.style.ts
import { StyleSheet } from 'react-native';

export const FeedbackStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    subheader: {
        fontSize: 20,
        fontWeight: '600',
        marginBottom: 8,
    },
    sectionContainer: {
        maxHeight: 200, // Adjust the max height as needed
        marginBottom: 16,
    },
    section: {
        fontSize: 16,
        lineHeight: 24,
    },
    input: {
        height: 80, // Adjust the height as needed
        borderColor: 'gray',
        borderWidth: 1,
        paddingLeft: 8,
        marginBottom: 16,
        textAlignVertical: 'top', // Ensure multiline text is top-aligned
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default FeedbackStyles;
