// App.navigator.tsx
import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { AppStackParamList } from '@@types/navigation';
import AuthNavigator from '@navigation/navigator/Auth.navigator';
import WorkspaceNavigator from '@navigation/navigator/Workspace.navigator';
import DocumentNavigator from '@navigation/navigator/Document.navigator';
import { AppRoutes } from '@navigation/routes';

const Stack = createStackNavigator<AppStackParamList>();

const AppNavigator: React.FC = () => {
    return (
        <Stack.Navigator>
            <Stack.Screen name={AppRoutes.AUTH} component={AuthNavigator} options={{headerShown: false}} />
            <Stack.Screen name={AppRoutes.WORKSPACE} component={WorkspaceNavigator} options={{headerShown: false}} />
            <Stack.Screen name={AppRoutes.DOCUMENT} component={DocumentNavigator}/>
            {/* Other screens */}
        </Stack.Navigator>
    );
};

export default AppNavigator;