// @components/writing/review/SectionCard.component.tsx
import React from 'react';
import { Card, TouchableRipple, Text } from 'react-native-paper';
import { StyleSheet } from 'react-native';
import { Section as SectionType, SectionID } from '@@types/documents/core';
import Markdown from 'react-native-markdown-display';

type SectionCardProps = {
    section: SectionType;
    active: boolean;
    onPress: () => void;
};

const SectionCard: React.FC<SectionCardProps> = ({ section, active, onPress }) => {
    return (
        <TouchableRipple
            onPress={onPress}
            accessibilityRole="button"
            accessibilityLabel={`${section.metadata.name}`}
            accessibilityState={{ expanded: active }}
        >
            <Card style={[styles.card, active && styles.activeCard]}>
                <Card.Title title={section.metadata.name} />
                <Card.Content>
                    {section.section_contents ? (
                        <Markdown>{section.section_contents}</Markdown>
                    ) : (
                        <Text>This section hasn't been started, tap or click this text, then the button that appears to get started.</Text>
                    )}
                </Card.Content>
            </Card>
        </TouchableRipple>
    );
};

const styles = StyleSheet.create({
    card: {
        marginVertical: 8,
    },
    activeCard: {
        borderColor: '#6200ee', // Example color for active section
        borderWidth: 2,
    },
});

export default React.memo(SectionCard, (prevProps, nextProps) => {
    return prevProps.active === nextProps.active && prevProps.section === nextProps.section;
});
