// @services/stubs/StubLifecycleClient.ts
import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DocumentID } from '@@types/documents/core';
import { CreateDocumentRequest, CreateDocumentResponse, ListAvailableDocumentsResponse, DocumentFetchResponse, OutlineResponse } from '@@types/clients/LifecycleAPI';

export class StubLifecycleClient implements LifecycleClient {
    async createDocument(request: CreateDocumentRequest): Promise<CreateDocumentResponse> {
        console.log('StubLifecycleClient.createDocument called', request);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            prompting_question: "What is the purpose of this document?",
            document_id: "stub-document-id"
        } as CreateDocumentResponse;
    }

    async fetchOutline(documentId: DocumentID): Promise<OutlineResponse> {
        console.log('StubLifecycleClient.fetchOutline called', documentId);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            outline: [
                {
                    metadata: {
                        id: "stub-section-id-1",
                        name: "Introduction",
                        index: 1,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    },
                    section_contents: null
                },
                {
                    metadata: {
                        id: "stub-section-id-2",
                        name: "Conclusion",
                        index: 2,
                        status: 'Unstarted',
                        last_edited: null,
                    },
                    section_contents: null
                }
            ]
        } as OutlineResponse;
    }

    async fetchDocument(documentId: DocumentID): Promise<DocumentFetchResponse> {
        console.log('StubLifecycleClient.fetchDocument called', documentId);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            id: documentId,
            name: "Stub Document",
            document_contents: [
                {
                    section_contents: "This is the introduction.",
                    metadata: {
                        id: "stub-section-id-1",
                        name: "Introduction",
                        index: 1,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    }
                },
                {
                    section_contents: "This is the conclusion.",
                    metadata: {
                        id: "stub-section-id-2",
                        name: "Conclusion",
                        index: 2,
                        status: 'Active',
                        last_edited: new Date().toISOString(),
                    }
                }
            ]
        } as DocumentFetchResponse;
    }

    async listAvailableDocuments(): Promise<ListAvailableDocumentsResponse> {
        console.log('StubLifecycleClient.listAvailableDocuments called');
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            documents: [
                {
                    id: "stub-document-id-1",
                    name: "Stub Document 1",
                    last_edited: new Date().toISOString(),
                    status: 'Active'
                },
                {
                    id: "stub-document-id-2",
                    name: "Stub Document 2",
                    last_edited: new Date().toISOString(),
                    status: 'Started'
                }
            ]
        } as ListAvailableDocumentsResponse;
    }
}
