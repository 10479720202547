import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppRoutes, DocumentRoutes, WorkspaceRoutes } from '@navigation/routes';
import { WorkspaceRoutesParamList, AppStackParamList } from '@@types/navigation';
import DocumentCreationComponent from '@components/workspace/DocumentCreation.component';
import { CreateDocumentRequest } from '@@types/clients/LifecycleAPI';
import { useLifecycleClient } from '@contexts/LifecycleClient.context';
import { useDocumentListContext } from '@contexts/DocumentList.context';
import { UserDocument } from '@@types/documents/core';
import {useDocumentContext} from "@contexts/Document.context";

type DocumentCreationProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.DOCUMENT_CREATION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const DocumentCreationPage: React.FC<DocumentCreationProps> = ({ navigation, appNavigator }) => {
    const { lifecycleClient } = useLifecycleClient();
    const { refreshDocuments } = useDocumentListContext();
    const { setDocument } = useDocumentContext();

    const handleCreateDocument = (title: string, style: string, outline: string) => {
        const createDocumentRequest: CreateDocumentRequest = {
            outline,
            style,
            name: title,
        };

        lifecycleClient.createDocument(createDocumentRequest)
            .then(response => {
                const { prompting_question: probingQuestion, document_id: documentID } = response;

                // Fetch the newly created document
                lifecycleClient.fetchDocument(documentID)
                    .then(document => {
                        console.log('Navigating with document:', document);

                        setDocument(document)
                        void refreshDocuments(); // Refresh the list of available documents

                        // Navigate to the Document Navigator and pass the document as a route parameter
                        appNavigator.navigate(AppRoutes.DOCUMENT, {
                            screen: DocumentRoutes.DOCUMENT_LEVEL_PROMPT,
                            params: {
                                probingQuestion, // If you want to pass probingQuestion as well
                            },
                        });
                    })
                    .catch(error => {
                        console.error('Error fetching the created document:', error);
                    });
            })
            .catch(error => {
                console.error('Error creating document:', error);
            });
    };

    return <DocumentCreationComponent onCreateDocument={handleCreateDocument} />;
};

export default DocumentCreationPage;
