import { ReactNode } from "react";
import { match } from "ts-pattern";
import { MaterialIcons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { DocumentStatus } from "@src/types/documents/core";

export const chooseStatusIcon = (item: { status: DocumentStatus }): ReactNode => 
     match (item)
        .with({status: 'Started'}, () => <AntDesign name="infocirlceo" size={24} color="yellow" />)
        .with({status: 'Active'}, () => <AntDesign name="checkcircleo" size={24} color="green" />)
        .with({status: 'Error'}, () => <AntDesign name="exclamationcircle" size={24} color="red" />)
        .with({status: 'Unstarted'}, () => <MaterialIcons name="not-started" size={24} color="black" />)
        // may want these later
        // .with({status: 'completed'}, () => <AntDesign name="checkcircleo" size={24} color="green" />)
        // .with({status: 'processing'}, () => <MaterialCommunityIcons name="progress-clock" size={24} color="grey" />)
        .exhaustive()

export const demoDocument = `
# Frementis et enim

## Virorum Iuppiter et Aeneae

Lorem markdownum mihi, et **unicus muneris** esse, Priamidas *certe*. Est et
Iasonis numina praedae, magni illi thyrsos: teste ego sororis numen, arce locuta
captantur. Illa quoque corona cum ille peperi rursus tamen, corpus: hostes.
Hebetastis in primum mutantur Pudor cera conatur pars natum orbem vero bracchia
nostra, sim.

## Os damno sapientius quod non capiti adicit

Deianira dolor aliud ferox fraxineam Athenae, quondam, marinas, tum mea animosa
falsi *labores* structis; soceri, favillae. Polydori vobis quaeritur valuere.

    system_favorites = gif_scroll_disk.gifCpm.gatewaySupercomputerSwitch(
            printerAjax(3) * whitelist, client_partition.bareInterlacedDisk(
            whiteFifo + flash_character_input), uriMicrocomputer);
    hardAlignment(3, -2, laser);
    vector = typefaceLeaderboardToken;
    nanometer = mipsKde;
    computer_opacity.direct_function_aix.export_windows_broadband(sink_hover_sql
            - bitmapAdd);

## Abstulit non viri terras non victis

Erat est flumen **aut**, iure tamen **clamare** tempora velat nocens conscelero
flavae. Temptamenta Bacchus iubae vitae consequitur mille cratera mari suus
praesens.

- Manet licet subita sedem contrarius concipe mali
- Dare deprendi longa
- Caderet vellentem gerens
- Moto silvas animi ne Cadmo
- Nisi dixit coniunx sacris aera Daphnes undas
- Precantia caeli

## Et quoque exclamat obstitit Aesone stabat

Cornua per laudis, retroque **in** iaculum funus pectora. Aquas ignes videnda
interitura dixerat arbor quaesitae quicquam: ait unus umeros certae *facies
Neptune* nobis armet, Pelasgos lapidumque. Nimium supero armentorum nunc,
mirabile alas tangi antrum *pectoribusque sedem* ad, flammae caesa, non tendebat
has genuisse. Fluvios vates furentem fecere languentique mensis agmen putes
circumfunditur *sustinet*.

Falsum pro minetur de invia, ne vivere vidi! Oculis adest amplexas, **erit
commenta** omnibus parte est vero cognovi monstris sociam! Polymestora semper
vagus: haut a quoniam concreta cornuque.

## Praelate posita vox dabatur

Sustinuere tibi! Ipsos cognoscit varios, sede, dederis est mirabile Aesacon
altera, postquam esse est, me Somni est! Vero est populo Libys utque scitetur
spatioque oculis illic, **religarat** nec arceat petis alti, cinis. Nulla ipsa
loqui claris vincite si dedit de per, quam non, cupiens.

Fine proelia agmina moles Mopso profecisse ingentia pietatem, annis **mora
agebat** pericula. In haut arbore repulsus Exigit horrendaque Areos edidit hac
oculis paelex destringunt huic lambendo
[instabat](http://www.utrecessu.io/attulit-praerupit)! Inducere ut fuit alta:
undas loqui, est pax anilibus Hector creatus accipit pharetram animum!
`