import { StyleSheet } from 'react-native';

export const FinalEditsStyles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
        backgroundColor: '#fff',
    },
    header: {
        fontSize: 24,
        marginBottom: 16,
    },
    input: {
        height: 150,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 16,
        paddingLeft: 8,
        paddingTop: 8,
        textAlignVertical: 'top',
    },
});
