import React, { createContext, useState, useContext, ReactNode, useCallback, useMemo } from 'react';
import { RequestState } from '@@types/LoadingContext';

// Context for state and error messages
const LoadingStateContext = createContext<RequestState | undefined>(undefined);
const LoadingErrorContext = createContext<{ errorMessage: string; additionalInfo?: string } | undefined>(undefined);

// Context for setters
const LoadingSettersContext = createContext<LoadingSetters | undefined>(undefined);

export type LoadingSetters = {
    setLoading: () => void;
    setSuccess: () => void;
    setIdle: () => void;
    setError: (message: string, info?: string) => void;
}
export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, setState] = useState<RequestState>('idle');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [additionalInfo, setAdditionalInfo] = useState<string | undefined>();

    const setLoading = useCallback(() => setState('loading'), []);
    const setSuccess = useCallback(() => setState('success'), []);
    const setIdle = useCallback(() => setState('idle'), []);
    const setError = useCallback((message: string, info?: string) => {
        setErrorMessage(message);
        setAdditionalInfo(info);
        setState('error');
    }, []);

    const settersValue = useMemo(() => ({
        setLoading,
        setSuccess,
        setIdle,
        setError,
    }), [setLoading, setSuccess, setIdle, setError]);

    return (
        <LoadingStateContext.Provider value={state}>
            <LoadingErrorContext.Provider value={{ errorMessage, additionalInfo }}>
                <LoadingSettersContext.Provider value={settersValue}>
                    {children}
                </LoadingSettersContext.Provider>
            </LoadingErrorContext.Provider>
        </LoadingStateContext.Provider>
    );
};

export const useLoadingState = (): RequestState => {
    const context = useContext(LoadingStateContext);
    if (context === undefined) {
        throw new Error('useLoadingState must be used within a LoadingProvider');
    }
    return context;
};

export const useLoadingError = (): { errorMessage: string; additionalInfo?: string } => {
    const context = useContext(LoadingErrorContext);
    if (context === undefined) {
        throw new Error('useLoadingError must be used within a LoadingProvider');
    }
    return context;
};

export const useLoadingSetters = (): {
    setLoading: () => void;
    setSuccess: () => void;
    setIdle: () => void;
    setError: (message: string, info?: string) => void;
} => {
    const context = useContext(LoadingSettersContext);
    if (context === undefined) {
        throw new Error('useLoadingSetters must be used within a LoadingProvider');
    }
    return context;
};
