import React from 'react';
import { FlatList } from 'react-native';
import { SectionID } from '@@types/documents/core';
import { useDocumentContext } from '@contexts/Document.context';
import Section from './Section.component';

type SectionListProps = {
    handleRefine: () => void;
    handleEdits: (newContents: string) => void;
    handleDraft: () => void;
};

const SectionList: React.FC<SectionListProps> = ({
                                                     handleRefine,
                                                     handleEdits,
                                                     handleDraft,
                                                 }) => {
    const { document, activeSectionID, setActiveSectionID } = useDocumentContext();

    if (!document) {
        return null; // Or a loading indicator
    }

    return (
        <FlatList
            data={document.document_contents}
            renderItem={({ item }) => (
                <Section
                    section={item}
                    active={activeSectionID === item.metadata.id}
                    handleRefine={handleRefine}
                    handleFinalEdits={handleEdits}
                    handleDraft={handleDraft}
                    setActiveSection={setActiveSectionID}
                />
            )}
            keyExtractor={item => item.metadata.id}
            extraData={activeSectionID} // Rerender only when active section changes
        />
    );
};

export default SectionList;
