// @pages/auth/Login.page.tsx
import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthRoutes, AppRoutes, WorkspaceRoutes } from '@navigation/routes';
import { AuthStackParamList, AppStackParamList } from '@@types/navigation';
import { useAccountClient } from '@contexts/AccountClient.context'; // Import the account client context hook
import { useJwt } from '@contexts/Jwt.context'; // Import the JWT context hook
import SexyLoginComponent from '@src/components/auth/SexyLogin.component';
import {Email, Password} from '@@types/clients/AccountAPI'

type LoginProps = {
    navigation: StackNavigationProp<AuthStackParamList, typeof AuthRoutes.LOGIN>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const LoginPage: React.FC<LoginProps> = ({ navigation, appNavigator }) => {
    const { accountClient } = useAccountClient(); // Use the account client context
    const { isTokenExpiring, refreshToken } = useJwt(); // Use the JWT context to check if the token is expiring and to refresh the token

    useEffect(() => {
        // Attempt to refresh the token on mount
        refreshToken();
    }, [refreshToken]);

    useEffect(() => {
        if (!isTokenExpiring) {
            // Navigate to the document selection page if the token is not expiring
            appNavigator.navigate(AppRoutes.WORKSPACE, {
                screen: WorkspaceRoutes.DOCUMENT_SELECTION,
            });
        }
    }, [isTokenExpiring, appNavigator]);

    const handleLogin = async (email: Email, password: Password) => {
        const response = await accountClient.login({ email, password }); // Token setting is handled by the account client
        if (response.status === 200) {
            appNavigator.navigate(AppRoutes.WORKSPACE, {
            screen: WorkspaceRoutes.DOCUMENT_SELECTION,
        });
        } else {
            console.error('Login failed', response);
        }
    };

    const handleCreateAccount = () => {
        navigation.navigate(AuthRoutes.CREATE_ACCOUNT);
    };

    return <SexyLoginComponent onLogin={handleLogin} onCreateAccount={handleCreateAccount} />
};

export default LoginPage;
