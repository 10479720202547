import React, { useState } from 'react';
import { View, TextInput, Button } from 'react-native';
import { DocumentCreationStyles as styles } from '@styles/DocumentCreation.style';

type DocumentCreationComponentProps = {
    onCreateDocument: (title: string, style: string, outline: string) => void;
};

const DocumentCreationComponent: React.FC<DocumentCreationComponentProps> = ({ onCreateDocument }) => {
    const [title, setTitle] = useState('');
    const [style, setStyle] = useState('');
    const [outline, setOutline] = useState('');

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder="Title"
                value={title}
                onChangeText={setTitle}
            />
            <TextInput
                style={styles.multiline}
                placeholder="Explain your intentions for the document and any style or tone guidelines."
                value={style}
                onChangeText={setStyle}
                multiline= {true}
            />
            <TextInput
                style={styles.multiline}
                placeholder="Please write your outline with one section title per line. Avoid using bullets or numbering."
                value={outline}
                onChangeText={setOutline}
                multiline= {true}
            />
            <Button
                title="Create Document"
                onPress={() => onCreateDocument(title, style, outline)}
            />
        </View>
    );
};

export default DocumentCreationComponent;
