// @pages/auth/Login.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthRoutes, AppRoutes, WorkspaceRoutes } from '@navigation/routes';
import { AuthStackParamList, AppStackParamList } from '@@types/navigation';
import { useAccountClient } from '@contexts/AccountClient.context'; // Import the account client context hook
import SexyCreateAccountComponent from '@src/components/auth/SexyCreateAccount.component';

import {Email, Password} from '@@types/clients/AccountAPI'

type CreateAccountProps = {
    navigation: StackNavigationProp<AuthStackParamList, typeof AuthRoutes.CREATE_ACCOUNT>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const CreateAccountPage: React.FC<CreateAccountProps> = ({ appNavigator }) => {
    const { accountClient } = useAccountClient(); // Use the account client context

    const handleCreateAccount = async (password: Password, email: Email) => {
        try {
             await accountClient.createAccount({  password, email });

            appNavigator.navigate(AppRoutes.WORKSPACE, {
                screen: WorkspaceRoutes.DOCUMENT_SELECTION,
            });
        } catch (error) {
            console.error('Account creation failed', error);
        }
    };

    return <SexyCreateAccountComponent onCreateAccount={handleCreateAccount}/>
};

export default CreateAccountPage;
