import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ImageBackground, Dimensions, Image } from 'react-native';
import { TextInput, Button, Card, Title, Paragraph } from 'react-native-paper';
import { GoogleSignin, GoogleSigninButton, statusCodes } from '@react-native-google-signin/google-signin';
import { LinearGradient } from 'expo-linear-gradient';
import { gradientColors } from '@styles/Fancy.style';
import { useAssets } from 'expo-asset';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import { AuthRoutes } from '@src/navigation/routes';
import { NavigationContainerRefWithCurrent, useNavigation } from '@react-navigation/native';
import { AuthStackParamList } from '@src/types/navigation';
import { sexyStyles } from '@src/styles/OuroborosSexyTheme.style';
import {Email, Password} from '@@types/clients/AccountAPI'

const { width, height } = Dimensions.get('window');

export type LoginComponentProps = {
  onLogin: (email: Email, password: Password) => void;
  onCreateAccount: () => void;
};
const SexyLoginPage: React.FC<LoginComponentProps> = ({  onLogin }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [assets, error] = useAssets([require('@public/ouroboros_logo_flat.png')]);

  const navigation: NavigationContainerRefWithCurrent<AuthStackParamList> = useNavigation();

  enabledFeatures[FeaturesEnum.SignInWithGoogle] ?? useEffect(() => {
    configureGoogleSignIn();
  }, []);

  const configureGoogleSignIn = () => {
    GoogleSignin.configure({
      webClientId: 'YOUR_WEB_CLIENT_ID', // From Google Cloud Console
      offlineAccess: false,
    });
  };

  const handleLogin = () => {
    onLogin(Email(email), Password(password))
  };

  const signInWithGoogle = async () => {
    try {
      await GoogleSignin.hasPlayServices();
      const userInfo = await GoogleSignin.signIn();
      console.log('User Info:', userInfo);
    } catch (error: any) {
      if (error.code === statusCodes.SIGN_IN_CANCELLED) {
        console.log('User cancelled the login flow');
      } else if (error.code === statusCodes.IN_PROGRESS) {
        console.log('Operation (e.g. sign in) is in progress already');
      } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
        console.log('Play services not available or outdated');
      } else {
        console.log('Some other error happened:', error);
      }
    }
  };

  const logo = assets ? <Image style={styles.logoStyle} source={{uri: assets[0].uri}}></Image> : null

  return (
    <LinearGradient
      colors={gradientColors}
      style={sexyStyles.background}
    >
      <View style={sexyStyles.container}>
        <Card style={sexyStyles.card}>
          <Card.Content>
            {logo}
            <Title style={sexyStyles.title}>Ouroboros Docs Login</Title>
            <TextInput
              label="Email"
              value={email}
              onChangeText={text => setEmail(text)}
              style={sexyStyles.input}
              keyboardType="email-address"
              autoCapitalize="none"
            />
            <TextInput
              label="Password"
              value={password}
              onChangeText={text => setPassword(text)}
              style={sexyStyles.input}
              secureTextEntry
            />
            <Button mode="contained" onPress={handleLogin} style={sexyStyles.button}>
              Login
            </Button>
            {enabledFeatures[FeaturesEnum.ForgotPassword] && <Button mode="text" onPress={() => navigation.navigate(AuthRoutes.FORGOT_PASSWORD)} style={sexyStyles.textButton}>
              Forgot Password?
            </Button>}
            {enabledFeatures[FeaturesEnum.SignInWithGoogle] && <GoogleSigninButton
              style={styles.googleButton}
              size={GoogleSigninButton.Size.Wide}
              color={GoogleSigninButton.Color.Dark}
              onPress={signInWithGoogle}
            />} 
            <Paragraph style={styles.signUpText}>Don't have an account?</Paragraph>
            <Button mode="text" onPress={() => navigation.navigate(AuthRoutes.CREATE_ACCOUNT)} style={sexyStyles.textButton}>
              Sign Up
            </Button>
          </Card.Content>
        </Card>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  logoStyle: {
    flex: 1,
    alignSelf: 'center',
    height: 250,
    width: 250
  },
  googleButton: {
    marginTop: 16,
  },
  signUpText: {
    marginTop: 16,
    textAlign: 'center',
  },
});

export default SexyLoginPage;