// @components/Prompt.component.tsx
import React, { useState } from 'react';
import { View, Text, TextInput, Button, ScrollView } from 'react-native';
import { PromptResponse } from '@@types/documents/prompt';
import { PromptStyles as styles } from '@styles/Prompt.style';
import { Markdown as MarkdownType} from '@@types/markdown';
import Markdown from "react-native-markdown-display";

type PromptComponentProps = {
    probingQuestion: MarkdownType;
    onSubmit: (userInput: string) => void;
};

const PromptComponent: React.FC<PromptComponentProps> = ({ probingQuestion, onSubmit }) => {
    const [userInput, setUserInput] = useState<string>('');

    const handleOnSubmit = () => {
        onSubmit(userInput);
        setUserInput(''); // Clear the input field
    };

    return (
        <View style={styles.container}>
            <Text style={styles.header}>Probing Questions</Text>
            <ScrollView style={styles.probingQuestionContainer}>
                <Markdown>{probingQuestion}</Markdown>
            </ScrollView>
            <TextInput
                style={styles.input}
                value={userInput}
                onChangeText={setUserInput}
                placeholder="Type your answer here..."
                multiline={true}
            />
            <Button title="Submit" onPress={handleOnSubmit} />
        </View>
    );
};

export default PromptComponent;
