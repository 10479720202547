import React, { useEffect } from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { WorkspaceRoutes, AppRoutes, DocumentRoutes } from '@navigation/routes';
import { WorkspaceRoutesParamList, AppStackParamList } from '@@types/navigation';
import { DocumentID } from '@@types/documents/core';
import DocumentSelectionComponent from '@components/workspace/DocumentSelection.component';
import { useLifecycleClient } from '@contexts/LifecycleClient.context'; // Use LifecycleClient instead of WritingClient
import { useDocumentContext } from '@contexts/Document.context';
import { useDocumentListContext } from '@contexts/DocumentList.context';
import { enabledFeatures, FeaturesEnum } from '@src/utils/enabledFeatures';
import SexyDocumentsListComponent from '@src/components/document/SexyDocumentList.component';

type DocumentSelectionProps = {
    navigation: StackNavigationProp<WorkspaceRoutesParamList, typeof WorkspaceRoutes.DOCUMENT_SELECTION>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const DocumentSelectionPage: React.FC<DocumentSelectionProps> = ({ appNavigator, navigation }) => {
    const { availableDocuments, fetchDocuments } = useDocumentListContext();
    const { lifecycleClient } = useLifecycleClient(); // Use LifecycleClient context
    const { setDocument } = useDocumentContext();

    useEffect(() => {
        void fetchDocuments(); // Fetch the documents when the component mounts
    }, [fetchDocuments]);

    const handleSelectDocument = (documentID: DocumentID) => {
        lifecycleClient.fetchDocument(documentID) // Use lifecycleClient to fetch the document
            .then(document => {
                setDocument(document);
                appNavigator.navigate(AppRoutes.DOCUMENT, {
                    screen: DocumentRoutes.REVIEW,
                });
            })
            .catch(error => {
                console.error('Error fetching document:', error);
                // Handle error (you may want to trigger an error state or show an alert)
            });
    };

    const handleCreateDocument = () => {
        navigation.navigate(WorkspaceRoutes.DOCUMENT_CREATION);
    };

    return (
        enabledFeatures[FeaturesEnum.SexyDocuments]
            ? <SexyDocumentsListComponent
                onCreateDocument={handleCreateDocument}
                onSelectDocument={handleSelectDocument}
                availableDocuments={availableDocuments} />
            : <DocumentSelectionComponent
                onCreateDocument={handleCreateDocument}
                onSelectDocument={handleSelectDocument}
                availableDocuments={availableDocuments} />
    );
};

export default DocumentSelectionPage;
