// @navigation/routes.ts
export const AppRoutes = {
    AUTH: 'Authentication',
    WORKSPACE: 'Select Or Create Document',
    DOCUMENT: 'Writing',
} as const;

export const WorkspaceRoutes = {
    DOCUMENT_SELECTION: 'Document Selection',
    DOCUMENT_CREATION: 'Document Creation',
} as const;

export const AuthRoutes = {
    LOGIN: 'Login',
    CREATE_ACCOUNT: 'Create Account',
    FORGOT_PASSWORD: 'Forgot Password',
} as const;

export const DocumentRoutes = {
    REVIEW: 'Review',
    FINAL_EDITS: 'FinalEdits',
    PROMPT: 'Prompt',
    REFINE: 'Refine',
    DOCUMENT_LEVEL_PROMPT: 'Document Planning',
} as const;

export type AppRouteKeys = keyof typeof AppRoutes;
export type AuthRouteKeys = keyof typeof AuthRoutes;
export type DocumentRouteKeys = keyof typeof DocumentRoutes;
export type WorkspaceRouteKeys = keyof typeof WorkspaceRoutes;
export type AppRouteValues = typeof AppRoutes[AppRouteKeys];
export type AuthRouteValues = typeof AuthRoutes[AuthRouteKeys];
export type DocumentRouteValues = typeof DocumentRoutes[DocumentRouteKeys];
export type WorkspaceRouteValues = typeof WorkspaceRoutes[WorkspaceRouteKeys];

