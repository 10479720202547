import API_URL from '@utils/config';
import React, { createContext, useContext, ReactNode, useMemo, useState, useEffect, useCallback, useRef } from 'react';

interface JwtContextType {
    isTokenExpiring: boolean;
    refreshToken: () => void;
}

const JwtContext = createContext<JwtContextType | undefined>(undefined);

export const JwtProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isTokenExpiring, setIsTokenExpiring] = useState(true);
    const lastRefreshTimeRef = useRef<number | null>(null);

    const refreshToken = useCallback(async () => {
        try {
            const currentTime = Date.now();
            if (lastRefreshTimeRef.current && (currentTime - lastRefreshTimeRef.current) < 60000) {
                // If the last refresh was less than a minute ago, skip the refresh
                console.log('Skipping token refresh, already refreshed within the last minute');
                return;
            }

            const response = await fetch(`${API_URL}/v1/auth/refresh-jwt`, {
                method: 'POST',
                credentials: 'include',
            });

            if (response.status === 401) {
                // Handle unauthorized response specifically
                console.warn('Credentials have expired. Please sign in again.');
                setIsTokenExpiring(true); // Set state to indicate the token is expiring
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to refresh JWT');
            }

            void await response;

            // Record the time of this refresh
            lastRefreshTimeRef.current = Date.now();

            scheduleTokenRefresh();
        } catch (error) {
            console.error('Error refreshing JWT:', error);
            setIsTokenExpiring(true); // Set state to indicate the token is expiring
        }
    }, []);

    const scheduleTokenRefresh = useCallback(() => {
        const currentTime = Date.now();
        const refreshTime = currentTime + 90 * 60 * 1000; // 90 minutes from now

        if (refreshTime > 0) {
            setTimeout(() => {
                refreshToken();
            }, refreshTime);
        } else {
            setIsTokenExpiring(true); // Token is close to expiration or expired
        }
    }, [refreshToken]);

    useEffect(() => {
        refreshToken(); // Initial call to refresh JWT when the component mounts
    }, [refreshToken]);

    const contextValue = useMemo(() => ({
        isTokenExpiring,
        refreshToken,
    }), [isTokenExpiring, refreshToken]);

    return (
        <JwtContext.Provider value={contextValue}>
            {children}
        </JwtContext.Provider>
    );
};

export const useJwt = (): JwtContextType => {
    const context = useContext(JwtContext);
    if (!context) {
        throw new Error('useJwt must be used within a JwtProvider');
    }
    return context;
};
