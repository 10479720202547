// @navigation/navigators/Workspace.navigator.tsx
import React from 'react';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import DocumentSelectionPage from '@pages/workspace/DocumentSelection.page';
import DocumentCreationPage from '@pages/workspace/DocumentCreation.page';
import { AppStackParamList, WorkspaceRoutesParamList } from '@@types/navigation';
import { AppRoutes, WorkspaceRoutes } from '@navigation/routes';
import { View } from 'react-native';
import DocumentDetailsPromptPage from "@pages/document/DocumentDetailsPrompt.page";
import { DocumentProvider } from '@contexts/Document.context';

const Stack = createStackNavigator<WorkspaceRoutesParamList>();

type WorkspaceNavigatorProps = {
    navigation: StackNavigationProp<AppStackParamList, typeof AppRoutes.WORKSPACE>;
};

const WorkspaceNavigator: React.FC<WorkspaceNavigatorProps> = ({ navigation }) => {
    return (
            <Stack.Navigator
                initialRouteName={WorkspaceRoutes.DOCUMENT_SELECTION}
                screenOptions={{
                    cardStyle: { backgroundColor: '#053A3A' },
                    headerLeft: () => null,
                    cardOverlay: () => (
                        <View
                            style={{
                                flex: 1,
                                backgroundColor: '#053A3A',
                            }}
                        />
                    ),
                    detachPreviousScreen: false
                }
            }
            >
                <Stack.Screen 
                    name={WorkspaceRoutes.DOCUMENT_SELECTION}
                    options={{ headerShown: true }}
                >
                    {props => <DocumentSelectionPage {...props} appNavigator={navigation} />}
                </Stack.Screen>


                <Stack.Screen
                    name={WorkspaceRoutes.DOCUMENT_CREATION}
                    options={{ headerShown: true }}
                >
                    {props => <DocumentCreationPage {...props} appNavigator={navigation} />}
                </Stack.Screen>
            </Stack.Navigator>
    );
};

export default WorkspaceNavigator;
