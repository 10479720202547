// @services/stubs/StubContentClient.ts
import { ContentClient } from '@services/clients/ContentClient';
import { DocumentID, Section } from '@@types/documents/core';
import {
    ReplaceSectionRequest,
    ReplaceSectionResponse,
    AnswerSectionQuestionsRequest,
    AnswerSectionQuestionsResponse,
    GenerateSectionQuestionsRequest,
    GenerateSectionQuestionsResponse,
    RefineRequest,
    RefineResponse,
    AnswerDocumentQuestionsRequest,
    GenerateDocumentQuestionsResponse
} from '@@types/clients/ContentAPI';
import { Markdown } from "@@types/markdown";

export class StubContentClient implements ContentClient {
    async refineSection(documentId: DocumentID, request: RefineRequest): Promise<RefineResponse> {
        console.log('StubContentClient.refineSection called', documentId, request);
        // Simulate a delay
        await new Promise(resolve => setTimeout(resolve, 100));
        // Return a stubbed response
        return {
            updated_section: {
                section_contents: request.feedback + "\n\nRefined content.",
                metadata: {
                    id: request.section_id,
                    name: "Sample Section",
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                }
            }
        } as RefineResponse;
    }

    async replaceSection(documentId: DocumentID, request: ReplaceSectionRequest): Promise<ReplaceSectionResponse> {
        console.log('StubContentClient.replaceSection called', documentId, request);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            updated_section: {
                section_contents: request.edits,
                metadata: {
                    id: request.section_id,
                    name: "Sample Section",
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                }
            }
        } as ReplaceSectionResponse;
    }

    async answerSectionQuestions(documentId: DocumentID, request: AnswerSectionQuestionsRequest): Promise<AnswerSectionQuestionsResponse> {
        console.log('StubContentClient.answerSectionQuestions called', documentId, request);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            updated_section: {
                section_contents: request.answers + "\n\nAnswered questions.",
                metadata: {
                    id: request.section_id,
                    name: "Sample Section",
                    index: 1,
                    status: 'Active',
                    last_edited: new Date().toISOString(),
                }
            }
        } as AnswerSectionQuestionsResponse;
    }

    async generateSectionQuestions(documentId: DocumentID, request: GenerateSectionQuestionsRequest): Promise<GenerateSectionQuestionsResponse> {
        console.log('StubContentClient.generateSectionQuestions called', documentId, request);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            probing_questions: "What is the main point of this section?\n\nWhat supporting details can be added?"
        } as GenerateSectionQuestionsResponse;
    }

    async answerDocumentQuestions(documentId: DocumentID, request: AnswerDocumentQuestionsRequest): Promise<void> {
        console.log('StubContentClient.answerDocumentQuestions called', documentId, request);
        await new Promise(resolve => setTimeout(resolve, 100));
        // No return value needed, just simulating the process.
    }

    async generateDocumentQuestions(documentId: DocumentID): Promise<GenerateDocumentQuestionsResponse> {
        console.log('StubContentClient.generateDocumentQuestions called', documentId);
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            probing_questions: "What is the purpose of this document?\n\nWhat are the key takeaways?"
        } as GenerateDocumentQuestionsResponse;
    }
}
