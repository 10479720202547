// @pages/auth/Login.page.tsx
import React from 'react';
import { StackNavigationProp } from '@react-navigation/stack';
import { AuthRoutes, AppRoutes, WorkspaceRoutes } from '@navigation/routes';
import { AuthStackParamList, AppStackParamList } from '@@types/navigation';
import { useAccountClient } from '@contexts/AccountClient.context';
import SexyCreateForgotPasswordComponent from '@src/components/auth/SexyForgotPassword.component';
import {Email} from '@@types/clients/AccountAPI'

type ForgotPasswordProps = {
    navigation: StackNavigationProp<AuthStackParamList, typeof AuthRoutes.FORGOT_PASSWORD>;
    appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
};

const ForgotPasswordPage: React.FC<ForgotPasswordProps> = ({ appNavigator }) => {
    const { accountClient } = useAccountClient(); // Use the account client context

    const handleForgotPasswordSubmit = async (email: Email) => {
        try {
            // TODO() Implement accountClient.forgotPassword(email)
            //  await accountClient.createAccount({  password, email });
            // appNavigator.navigate(AppRoutes.WORKSPACE, {
            //     screen: WorkspaceRoutes.DOCUMENT_SELECTION,
            // });
        } catch (error) {
            console.error('Account creation failed', error);
        }
    };

    return <SexyCreateForgotPasswordComponent onForgotPasswordSubmit={handleForgotPasswordSubmit}/>
};

export default ForgotPasswordPage;
