// @services/ouroboros/DefaultLifecycleClient.ts
import { LifecycleClient } from '@services/clients/LifecycleClient';
import { DocumentID } from '@@types/documents/core';
import { CreateDocumentRequest, CreateDocumentResponse, ListAvailableDocumentsResponse, DocumentFetchResponse, OutlineResponse } from '@@types/clients/LifecycleAPI';
import API_URL from '@utils/config';
import * as t from 'io-ts';
import { v7 as uuidv7 } from 'uuid';
import { LoadingSetters } from '@contexts/Loading.context';
import { isRight } from 'fp-ts/Either';

export class DefaultLifecycleClient implements LifecycleClient {
    private readonly setLoading: () => void;
    private readonly setSuccess: () => void;
    private readonly setError: (message: string, info?: string) => void;

    constructor({ setLoading, setSuccess, setError }: LoadingSetters) {
        this.setLoading = setLoading;
        this.setSuccess = setSuccess;
        this.setError = setError;
    }

    private generateTraceId(): string {
        return uuidv7();
    }

    private async fetchWithValidation<T>(url: string, options: RequestInit, validator: t.Type<T>): Promise<T> {
        const traceId = this.generateTraceId();
        options.headers = {
            ...options.headers,
            'X-Trace-ID': traceId,
        };

        options.credentials = 'include';
        this.setLoading();

        try {
            const response = await this.fetchData(url, options);
            return this.validateResponse(response, validator, traceId);
        } catch (error) {
            this.handleError(error, traceId);
            throw error;
        }
    }

    private async fetchData(url: string, options: RequestInit): Promise<Response> {
        const response = await fetch(url, {
            ...options,
            credentials: 'include'  // Ensure credentials are included with every request
        });
        if (!response.ok) {
            throw new Error(`Server responded with an error: ${response.status}`);
        }
        return response;
    }

    private async validateResponse<T>(response: Response, validator: t.Type<T>, traceId: string): Promise<T> {
        const data = await response.json();
        if (!isRight(validator.decode(data))) {
            throw new Error(`Response validation failed (Trace ID: ${traceId})`);
        }
        this.setSuccess();
        return data;
    }

    private handleError(error: unknown, traceId: string): void {
        if (error instanceof Error) {
            this.setError(error.message, `Trace ID: ${traceId}`);
        } else {
            this.setError('An unknown error occurred', `Trace ID: ${traceId}`);
        }
    }

    async createDocument(request: CreateDocumentRequest): Promise<CreateDocumentResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            },
            CreateDocumentResponse
        );
    }

    async fetchOutline(documentId: DocumentID): Promise<OutlineResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document/outline`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                }
            },
            OutlineResponse
        );
    }

    async fetchDocument(documentId: DocumentID): Promise<DocumentFetchResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer/document`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Document-ID': documentId
                }
            },
            DocumentFetchResponse
        );
    }

    async listAvailableDocuments(): Promise<ListAvailableDocumentsResponse> {
        return this.fetchWithValidation(
            `${API_URL}/v1/apps/writer`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            },
            ListAvailableDocumentsResponse
        );
    }
}
