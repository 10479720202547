// @contexts/DocumentList.context.tsx
import React, { createContext, useState, useContext, useCallback, ReactNode, useMemo } from 'react';
import { DocumentMetadata } from '@@types/documents/core';
import { useLoadingSetters } from '@contexts/Loading.context';  // Updated to use LoadingSetters context
import { useLifecycleClient } from '@contexts/LifecycleClient.context';

export interface DocumentListContextType {
    availableDocuments: DocumentMetadata[];
    fetchDocuments: () => Promise<void>;
    refreshDocuments: () => Promise<void>;
}

const DocumentListContext = createContext<DocumentListContextType | undefined>(undefined);

export const DocumentListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [availableDocuments, setAvailableDocuments] = useState<DocumentMetadata[]>([]);

    // Extract only the stable setters from the loading context
    const { setLoading, setSuccess, setError } = useLoadingSetters();
    const { lifecycleClient } = useLifecycleClient();

    const fetchDocuments = useCallback(async () => {
        setLoading();
        try {
            const response = await lifecycleClient.listAvailableDocuments();
            setAvailableDocuments(response.documents);
            setSuccess();
        } catch (error) {
            console.error('Error fetching available documents:', error);
            if (error instanceof Error) {
                setError('Failed to fetch documents', error.message);
            }
        }
    }, [lifecycleClient, setLoading, setSuccess, setError]);

    const refreshDocuments = useCallback(async () => {
        await fetchDocuments();
    }, [fetchDocuments]);

    const contextValue = useMemo(() => ({
        availableDocuments,
        fetchDocuments,
        refreshDocuments,
    }), [availableDocuments, fetchDocuments, refreshDocuments]);

    return (
        <DocumentListContext.Provider value={contextValue}>
            {children}
        </DocumentListContext.Provider>
    );
};

export const useDocumentListContext = (): DocumentListContextType => {
    const context = useContext(DocumentListContext);
    if (!context) {
        throw new Error('useDocumentListContext must be used within a DocumentListProvider');
    }
    return context;
};
