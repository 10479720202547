import * as t from "io-ts";
import {DocumentID, DocumentMetadata, OutlineSection, UserDocument} from "@@types/documents/core";

export const CreateDocumentRequest = t.type({
    outline: t.string,
    style: t.string,
    name: t.string,
});
export type CreateDocumentRequest = t.TypeOf<typeof CreateDocumentRequest>;

export const CreateDocumentResponse = t.type({
    prompting_question: t.string,
    document_id: DocumentID
});
export type CreateDocumentResponse = t.TypeOf<typeof CreateDocumentResponse>;

export const ListAvailableDocumentsResponse = t.type({
    documents: t.array(DocumentMetadata)
});
export type ListAvailableDocumentsResponse = t.TypeOf<typeof ListAvailableDocumentsResponse>;

export const DocumentFetchResponse = UserDocument
export type DocumentFetchResponse = t.TypeOf<typeof DocumentFetchResponse>;

export const OutlineResponse = t.type({
    outline: t.array(OutlineSection)
});
export type OutlineResponse = t.TypeOf<typeof OutlineResponse>;
